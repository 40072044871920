/* main container */

.about-section {
    background-color: #003459;
    position: relative;
    padding-bottom: 5rem;
    width: 100%;
}


/* inner container */

.about-content {
    color: rgb(245, 245, 245);
    padding: 25rem 0 0 0;
    width: 90%;
}


/* hello heading */

.about-header {
    margin-bottom: 40rem;
    width: 100%;
}

.heading {
    font-size: 3em;
    padding-left: 2.78rem;
}


/* name */

.heading-name {
    font-size: 4em;
    padding-left: 2.78rem;
}

.main-name {
    color: #00A8E8;
}


/* social links */

.heading-social {
    margin: auto;
    padding: 1rem 0 1rem 2.78rem;
    text-align: left;
}

.heading-links {
    margin: 0.5rem;
}

.heading-links:focus,
.heading-links:hover {
    border-color: #00171F;
    background: #00171F;
    outline: none !important;
    outline-offset: none !important;
    position: relative;
}


/* about me description */

.about-me {
    color: white;
    text-align: left;
}

.about-h1 {
    font-Size: 3.5em;
    font-Weight: bold;
    margin-bottom: 2rem;
}

.about-description {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
}

.about-text {
    color: #00A8E8;
}

.profile-img {
    border: 0.2rem solid white;
    width: 100%;
}


/* typewriter */

.Typewriter {
    padding: 1.5rem 0 1.5rem 2.78rem;
}

.Typewriter__wrapper {
    color: #00A8E8;
    font-size: 2.5rem;
    font-weight: 600;
}

.Typewriter__cursor {
    color: #b562d6;
    font-size: 2.4em;
}


/* waving hand */

.waving-hand {
    animation-name: wave-animation;
    animation-duration: 1.9s;
    animation-iteration-count: infinite;
    display: inline-block;
    transform-origin: 80% 100%;
}

@keyframes wave-animation {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(14deg);
    }
    20% {
        transform: rotate(-8deg);
    }
    30% {
        transform: rotate(14deg);
    }
    40% {
        transform: rotate(-4deg);
    }
    50% {
        transform: rotate(10deg);
    }
    60% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}


/* media queries */

@media only screen and (max-width: 1440px) {
    .about-content {
        padding-top: 20rem;
    }
    .about-header {
        margin-bottom: 20rem;
    }
}

@media only screen and (height: 1368px) {
    .about-header {
        margin-bottom: 43rem;
    }
    .about-description {
        font-size: 1.25rem;
    }
}

@media only screen and (height: 1024px) {
    .about-header {
        margin-bottom: 26rem;
    }
}

@media only screen and (width: 1024px) {
    .about-description {
        font-size: 1.25rem;
    }
}

@media only screen and (height: 1180px) {
    .about-header {
        margin-bottom: 33rem;
    }
}

@media only screen and (max-width: 820px) {
    .about-h1 {
        font-Size: 2.8rem;
    }
    .about-description {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 768px) {
    .about-content {
        padding-top: 15rem;
    }
    .about-h1 {
        font-Size: 2.7rem;
    }
    .Typewriter {
        height: 9rem !important;
    }
}

@media only screen and (width: 540px) {
    .about-content {
        padding-top: 13.5rem;
    }
    .about-header {
        margin-bottom: 18rem;
    }
    .about-h1 {
        font-Size: 2.4rem;
    }
    .profile-img {
        width: 80%;
        display: block;
        margin-top: 0.5rem;
        margin-left: auto;
        margin-right: auto;
    }
    .heading {
        font-size: 2.5em;
    }
    .heading-name {
        font-size: 2.6rem;
    }
    .Typewriter {
        height: 8rem !important;
    }
    .Typewriter__wrapper {
        font-size: 2rem;
    }
    .Typewriter__cursor {
        font-size: 1.9em;
    }
}

@media only screen and (max-width: 425px) {
    .about-content {
        padding-top: 12.5rem;
        font-size: 0.8rem;
        text-align: left;
    }
    .heading {
        font-size: 3em;
        padding-left: 0rem;
    }
    .heading-name {
        font-size: 4em;
        padding-left: 0rem;
    }
    .heading-social {
        padding-left: 0.6rem;
    }
    .heading-links {
        margin: 0.6rem;
        width: 6rem;
    }
    .Typewriter {
        padding-left: 0rem;
        height: 9rem !important;
    }
}

@media only screen and (max-width: 414px) {
    .about-content {
        padding-top: 15.5rem;
    }
    .about-h1 {
        font-Size: 2.2rem;
    }
    .about-description {
        font-size: 1.1rem;
    }
    .profile-img {
        width: 80%;
        display: block;
        margin-top: 0.5rem;
        margin-left: auto;
        margin-right: auto;
    }
    .heading-links {
        width: 5.8rem;
    }
    .Typewriter__wrapper {
        font-size: 2rem;
    }
    .Typewriter__cursor {
        font-size: 1.9em;
    }
}

@media only screen and (max-width: 393px) {
    .heading-links {
        width: 5.4rem;
    }
    .Typewriter__wrapper {
        font-size: 1.7rem;
    }
    .Typewriter__cursor {
        font-size: 1.6em;
    }
}

@media only screen and (max-width: 375px) {
    .about-content {
        padding-top: 13.5rem;
    }
    .about-description {
        font-size: 0.97rem;
    }
    .profile-img {
        width: 80%;
        display: block;
        margin-top: 0.5rem;
        margin-left: auto;
        margin-right: auto;
    }
    .heading {
        font-size: 2.5em;
    }
    .heading-name {
        font-size: 2.6rem;
    }
    .heading-links {
        width: 5.1rem;
    }
    .Typewriter {
        height: 8rem !important;
    }
}

@media only screen and (max-width: 360px) {
    .heading-links {
        width: 4.8rem;
    }
}

@media only screen and (max-width: 320px) {
    .heading {
        font-size: 2rem;
    }
    .heading-name {
        font-size: 2.5rem;
    }
    .heading-links {
        width: 5.1rem;
    }
    .Typewriter {
        height: 8rem !important;
    }
}

@media only screen and (max-width: 280px) {
    .about-header {
        margin-bottom: 10rem;
    }
    .about-description {
        font-size: 1rem;
    }
    .heading {
        font-size: 1.6rem;
    }
    .about-h1 {
        font-Size: 1.9rem;
    }
    .heading-name {
        font-size: 2.3rem;
    }
    .heading-links {
        width: 5rem;
    }
    .Typewriter {
        height: 6rem !important;
    }
    .Typewriter__wrapper {
        font-size: 1.4rem;
    }
    .Typewriter__cursor {
        font-size: 1.3em;
    }
}