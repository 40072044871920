/* navbar */

.navbar-dark {
    font-size: bold;
}

.nav-link {
    color: #fff !important;
    margin: 0 0.5rem;
}

.navbar-nav .nav-item a::after {
    border-radius: 16px;
    background: #00A8E8;
    bottom: 1px;
    content: "";
    display: block;
    height: 5px;
    left: 0;
    position: relative;
    transition: all 0.3s ease-out 0s;
    width: 0;
    z-index: -1;
}

.navbar-nav .nav-item a:hover::after {
    width: 100%;
}


/* .fas {
    font-size: 1rem;
    margin: 0 0.5rem;
} */

.brand {
    color: #fff !important;
    font-size: 2rem;
    font-weight: bold;
}


/* navbar class selector */

.sticky {
    background-color: rgba(0, 126, 167, 0.3) !important;
    box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
    backdrop-filter: blur(15px) !important;
    transition: all 0.3s ease-out 0s !important;
}

.navbar {
    font-size: 1.2rem !important;
    position: fixed !important;
    padding: 0.3rem 2rem !important;
    transition: all 0.3s ease-out 0s !important;
}


/* navbar toggler */

.navbar-toggler {
    background-color: transparent !important;
    border-color: transparent !important;
    position: relative !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    box-shadow: none;
    outline: 0 !important;
}

.navbar-toggler span {
    background-color: #fff !important;
    display: block !important;
    height: 3px !important;
    left: 0 !important;
    margin: 0.5rem 0 !important;
    opacity: 1 !important;
    transform: rotate(0deg) !important;
    width: 30px !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    left: 12px !important;
    position: absolute !important;
    opacity: 0.9 !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    background-color: transparent !important;
    height: 12px !important;
    visibility: hidden !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    left: 12px !important;
    position: absolute !important;
    opacity: 0.9 !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
}


/* media queries */

@media only screen and (max-width: 1024px) {
    .nav-link {
        font-size: 1rem;
        width: auto;
    }
}

@media only screen and (max-width: 991px) {
    .navbar {
        background-color: rgba(0, 126, 167, 0.3);
        backdrop-filter: blur(15px);
    }
    .navbar-nav .nav-item a::after {
        display: none !important;
    }
}

@media only screen and (max-width: 768px) {
    .navbar-container {
        width: 150%
    }
}

@media only screen and (max-width: 425px) {
    .brand {
        font-size: 1.7rem;
    }
    .navbar {
        padding: 0.3rem 1.3rem !important;
    }
}

@media only screen and (max-width: 271px) {
    .brand {
        font-size: 1.4rem;
    }
    .navbar-toggler span {
        height: 2.2px !important;
        width: 25px !important;
    }
}