/* main container */

.main-contact-form {
    background-image: url(/public/images/arches.png);
    background-size: 15%;
    background-position: center;
    background-repeat: repeat;
    background-color: #f7f7f7;
    border-radius: 1rem;
    position: relative;
    padding: 3rem;
}


/* inner container */

.contact-form-heading {
    background: rgba(255, 255, 255, 0.2);
    color: #ae2012;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin: 2rem 0;
    padding: 0.5rem 0;
    text-align: center;
    text-shadow: 2px 2px 0px #FFFFFF, 5px 4px 0px rgba(0, 0, 0, 0.15);
}

.contact-span {
    color: #003459;
    font-size: 1.5rem !important;
}

.label-icon {
    color: #000;
    margin-left: 2rem;
}

.label-input {
    white-space: nowrap;
}

.input-box {
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid #ced4da;
    border-radius: 0.5rem;
    background-color: #f8f9fa;
    margin: 0 0 0.5rem 0.5rem;
    padding: 0.4rem;
    width: 70%;
}

.form-div {
    margin-bottom: 5px;
    width: 100%;
}

::placeholder {
    color: #003459;
    font-weight: bold;
    padding: 0 0 0 0.5rem;
    opacity: 0.5;
}

.message-box {
    background-color: #f8f9fa;
    border: 2px solid #ced4da;
    border-radius: 0.5rem;
    display: flex;
    height: 20rem;
    justify-content: center;
    max-height: 20rem;
    padding: 0.3rem;
    resize: none;
    width: 100%;
}

.submit-box {
    background-color: #fee6e3;
    border-radius: 0.5rem;
    box-shadow: 4px 6px rgba(0, 0, 0, 0.50);
    margin: 1rem 0 0;
    display: block;
    padding: 0.5rem 1rem;
    transition: box-shadow 0.3s ease-in-out;
}

.submit-box:hover {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out;
}

.contactme-form {
    display: flex;
    flex-wrap: wrap;
}


/* media queries */

@media only screen and (width: 820px) {
    .main-contact-form {
        padding: 0 3rem;
    }
}

@media only screen and (width: 540px) {
    .main-contact-form {
        padding: 0 1rem;
    }
    .message-box {
        height: 17rem;
    }
}

@media only screen and (max-width: 414px) {
    .main-contact-form {
        padding: 0 1rem;
    }
    .form-div {
        margin-bottom: 0px;
    }
    .label-icon {
        margin-left: 1.2rem;
    }
    .message-box {
        height: 15rem;
    }
    .submit-box {
        margin-left: auto;
        margin-right: auto;
        background-color: #f06449;
    }
    .contact-form-heading {
        text-shadow: 2px 2px 2px #FFFFFF, 0px 2px 0px rgba(0, 0, 0, 0.15);
    }
}

@media only screen and (width: 280px) {
    .main-contact-form {
        padding: 0 0.5rem;
    }
    .contact-form-heading {
        margin: 1rem 0;
        text-shadow: 2px 2px 2px #FFFFFF, 0px 2px 0px rgba(0, 0, 0, 0.15);
    }
    .form-div {
        margin-bottom: 0px;
    }
    .message-box {
        height: 10rem;
    }
    .label-icon {
        margin-left: 0.5rem;
    }
    .submit-box {
        margin-left: auto;
        margin-right: auto;
        background-color: #f06449;
    }
}