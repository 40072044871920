.footer {
    background-color: #00171F;
    position: relative;
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 8px;
}

.footer-copywright {
    text-align: center;
}

.footer-body {
    text-align: center;
}

.footer-icons {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.social-icons {
    margin: 0 2rem;
}

.footer h3 {
    font-size: 1em;
    color: white;
    margin: 0.3rem 0;
    padding: 0.1rem;
}

.footer-icons {
    margin: 0.3rem 0;
    padding: 0rem;
}


/* media queries */

@media only screen and (max-width: 912px) {
    .social-icons {
        margin: 0 1.5rem;
    }
}

@media only screen and (max-width: 767px) {
    .footer-copywright {
        text-align: center;
    }
    .footer-body {
        text-align: center;
    }
}