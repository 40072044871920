/* main container */

.skillset {
    background-image: url(/public/images/asfalt-light.png);
    background-color: #d1dcd5;
    background-size: 100%;
    background-position: center;
    background-repeat: repeat;
    position: relative;
}


/* heading effect */

.paint-stroke {
    background-image: url(/public/images/brush-stroke.png);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 3rem;
    padding: 1rem;
    transform: rotate(-3deg);
    text-align: center;
    width: 18rem;
}

.tools-stroke {
    transform: rotate(3deg);
}


/* education and experience card */

.skillset-inner {
    display: flex;
    flex-wrap: wrap;
}

.edu-exp-card-heading {
    color: #f0b90b;
    display: block;
    margin: 1rem 0;
    position: relative;
    width: 100%
}

.edu-exp-card-container {
    color: #fff;
    height: auto;
    margin-bottom: 2rem;
    width: 45rem;
}

.edu-exp-card {
    background-color: #003459;
    border-radius: 1rem;
    border: 0.1rem solid #003459;
    padding: 1rem;
}

.edu-exp-card:hover {
    border: 0.1rem solid #f0b90b;
    box-shadow: 0 0 5px #ffee10;
}

.exp-edu-name {
    color: #f0b90b;
    font-weight: 700;
}

.exp-edu-title {
    font-family: 'EB Garamond', serif;
    font-size: 2.2rem;
    text-shadow: 2px 2px 8px #00A8E8;
}

.exp-edu-description {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.3;
}


/* skill section */

.skills {
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
}

.skill-tech-stack-inner {
    width: 50rem;
}

.skill-tech-stack-heading {
    color: #f0b90b;
    display: flex;
    justify-content: flex-start;
    margin: 1rem 0;
    position: relative;
    width: 100%
}

.tech-heading {
    justify-content: flex-end;
}


/* media queries */

@media only screen and (max-width: 414px) {
    .paint-stroke {
        display: block;
        font-size: 2.6rem;
        margin-left: auto;
        margin-right: auto;
        transform: rotate(0deg);
    }
    .exp-edu-title {
        font-size: 1.84rem;
    }
    .exp-edu-duration {
        font-size: 0.9rem;
    }
    .exp-edu-name {
        font-size: 1.3rem;
    }
    .exp-edu-description {
        font-size: 1rem;
        line-height: 1.3;
    }
    .exp-edu-list {
        font-size: 0.9rem;
    }
}

@media only screen and (width: 280px) {
    .paint-stroke {
        font-size: 2rem;
        width: 100%;
    }
    .edu-exp-card-container {
        padding: 0rem;
    }
    .exp-edu-title {
        font-size: 1.4rem;
    }
    .exp-edu-duration {
        font-size: 0.7rem;
    }
    .exp-edu-name {
        font-size: 1rem;
    }
    .exp-edu-description {
        font-size: 0.89rem;
    }
    .exp-edu-list {
        font-size: 0.7rem;
    }
}