/* main container */

.card-main {
    height: 35rem;
    margin: 1rem;
    width: 30rem;
}


/* card inner */

.card-img {
    height: 300px;
}

.card-body {
    display: flex;
    flex-direction: column;
}


/* card inner children */

.card-links {
    margin-top: auto;
    padding-bottom: 0.2rem;
}

.link-icon {
    background-color: #e4e4e4;
    box-shadow: 0px 4px 2px 1px #368ed6;
    border: 2px solid #368ed6;
    border-radius: 1rem;
    color: #368ed6;
    font-weight: 500;
    margin: 0.5rem;
    padding: 0.4rem;
    text-decoration: none;
    transition: 0.1s;
}


/* media queries */

@media only screen and (max-width: 414px) {
    .card-img {
        height: 14rem;
        width: auto;
    }
    .card-main {
        height: 28rem;
        width: 20rem;
    }
    .card-title {
        font-size: 1.5rem !important;
    }
    .card-text {
        font-size: 0.8rem;
    }
}