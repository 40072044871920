/* main container */

.cert-contact-container {
    background-image: url(/public/images/asfalt-light.png);
    background-color: #d1dcd5;
    background-size: 100%;
    background-position: center;
    background-repeat: repeat;
    padding-bottom: 3rem;
}

.cert-contact-section {
    display: flex;
    flex-wrap: wrap;
}


/* certificates */

.certificates-heading {
    color: #f0b90b;
    display: inline-block;
    margin: 1.5rem;
    position: relative;
    width: 100%
}

.certificates {
    color: #fff;
    height: auto;
    margin-bottom: 2rem;
    width: 45rem;
}

.certificates-div {
    background-color: #003459;
    border-radius: 1rem;
    padding: 1rem;
}

.cert-caro {
    display: flex;
    justify-content: center;
}


/* contact */

.contact {
    color: #fff;
    height: 60rem;
    width: 45rem;
}

.contact-div {
    border-radius: 1rem;
}

.contact-heading {
    color: #f0b90b;
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
    position: relative;
    width: 100%;
}

.contact-stroke {
    transform: rotate(3deg);
}

.form {
    background: #003459;
    border-radius: 1rem;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: 1rem;
    height: 50rem;
    height: 50rem;
}


/* media queries */

@media only screen and (width: 820px) {
    .form {
        height: 43rem;
    }
    .contact {
        height: 55rem;
    }
}

@media only screen and (width: 540px) {
    .form {
        height: 41rem;
    }
    .contact {
        height: 50rem;
    }
}

@media only screen and (max-width: 414px) {
    .certificates {
        width: 100%;
    }
    .contact-stroke {
        transform: rotate(0deg);
    }
    .contact {
        height: 49rem;
    }
    .form {
        height: 41rem;
    }
}

@media only screen and (width: 280px) {
    .certificates-heading {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .certificates {
        padding: 0rem;
    }
    .contact {
        padding: 0rem;
        height: 41rem;
    }
    .form {
        height: 34rem;
    }
}