.skillstack-center {
    justify-content: center;
    padding-bottom: 3.1rem;
}

.skill-tech-icons {
    background-color: #adb5bd;
    border: 0.1rem solid rgba(0, 126, 167, 0.637);
    box-shadow: 4px 5px 4px 3px rgba(0, 126, 167, 0.137);
    border-radius: 0.6rem;
    font-size: 3.5rem;
    margin: 0.5rem;
    opacity: 0.93;
    padding: 0.2rem;
    text-align: center;
    transition: all 0.1s ease 0s;
    vertical-align: middle;
}

.skill-tech-icons:hover {
    color: #ffee10;
    border: 0.1rem solid #f0b90b;
    box-shadow: 0 0 5px #ffee10;
    text-shadow: 0 0 5px #ffee10;
}

.skill-icon-name {
    font-size: 1rem;
}


/* media queries */

@media only screen and (max-width: 414px) {
    .skill-tech-icons {
        font-size: 3rem;
    }
}

@media only screen and (width: 540px) {
    .skill-icon-name {
        font-size: 1.2rem;
    }
}

@media only screen and (width: 280px) {
    .skill-tech-icons {
        width: 43%;
    }
    .skill-icon-name {
        font-size: 1.1rem;
    }
}