/* main container */

.message-container {
    background-color: #d1dcd5;
    padding: 5rem;
}


/* inner-container */

.inner-message-container {
    background-color: #003459;
    background-image: url(/public/images/climpek.png);
    border: 0.1rem solid #d1dcd5;
    border-radius: 1rem;
    height: auto;
    margin: 5rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding: 2rem;
    width: 60%;
    max-width: 60rem;
}

.inner-message-container:hover {
    border: 0.1rem solid #f0b90b;
    box-shadow: 0 0 5px #ffee10;
}


/* sender name */

.name-message {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.name {
    color: #fff;
    text-shadow: 2px 2px 8px #00A8E8;
    display: block;
    word-wrap: break-word;
}

.name-inner {
    background-color: #003459;
    border: 0.3rem solid #fff;
    border-radius: 3rem;
    font-family: 'EB Garamond', serif;
    font-size: 6rem;
    padding: 0.5rem;
    width: 100%;
}

.message-paragraph,
.name-inner {
    color: #ffee10;
    display: block;
    font-weight: 500;
}


/* paragraph */

.message-paragraph {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.7rem;
    margin: 1rem;
    text-shadow: 2px 2px 5px #f0b90b;
}


/* button */

.button-div {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    margin: 1rem;
}

.back-button {
    background-color: #fee6e3;
    border-radius: 0.5rem;
    box-shadow: 4px 6px;
    padding: 0.5rem 1rem;
    transition: box-shadow 0.3s ease-in-out;
}

.back-button:hover {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out;
}


/* media queries */

@media only screen and (max-width: 1280px) {
    .message-container {
        padding: 0.6rem;
    }
}

@media only screen and (max-width: 1121px) {
    .inner-message-container {
        width: auto;
    }
    .name-inner {
        font-size: 5rem;
    }
}

@media only screen and (max-width: 850px) {
    .name-inner {
        font-size: 1.6rem;
    }
    .message-paragraph {
        font-size: 1rem;
    }
    .back-button {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 375px) {
    .name-inner {
        width: 100%;
        font-size: 1.4rem;
        border-radius: 1rem;
        margin-left: 0;
    }
}

@media only screen and (max-width: 320px) {
    .message-paragraph {
        width: 100%;
        margin-left: 0;
    }
    .button-div {
        width: 100%;
        margin-left: 0;
    }
    .back-button {
        font-size: 0.5rem;
    }
}

@media only screen and (width: 280px) {
    .inner-message-container {
        width: 100%;
    }
    .name-inner {
        font-size: 1rem;
        width: 100%;
    }
    .message-container {
        padding: 0.5rem;
    }
}