/* main container */

.carousel {
    width: 100%;
    height: 50rem;
    background-color: #fff;
    border-radius: 1rem;
}

.d-block {
    border-radius: 1rem 1rem 0 0;
}

.carousel-inner {
    border-radius: 1rem 1rem 0 0;
}


/* carousel item */

.carousel-caption {
    position: relative;
    top: 0;
    left: 0;
}

.carousel-img {
    width: 800px;
    height: 500px;
}

.caro-caption {
    margin: 0 3rem;
    display: flex;
    color: #003459;
    font-weight: bold;
    font-size: 1.7rem;
    text-align: left;
}

.caro-p {
    color: #00a8e8;
    font-weight: bold;
    font-size: 1.3rem;
    text-align: left;
    margin-left: 3rem;
}

.skill {
    background-color: #f06449;
    color: #fff;
    font-weight: 500;
    border-radius: 1rem;
    padding: 0rem 1rem;
    display: inline-block;
    overflow-wrap: break-word;
    margin: 0.3rem 0;
}

.skill-div {
    text-align: left;
    margin: 1rem 3rem;
    line-height: 2.5rem;
}

.caro-link {
    display: inline-block;
}

.caro-link-icon {
    color: #fdfffc;
    border: 1px solid #000;
    background-color: #000;
    border-radius: 1rem;
    padding: 0.2rem;
}

.caro-link-icon:hover {
    background-color: #f06449;
    border: #f06449;
}


/* carousel card movement properties */

.carousel__inner-slide {
    margin-right: 20px;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#ff000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#ff000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-item {
    transition: opacity 1s ease-in-out !important;
}

li {
    transition: opacity 1s ease !important;
}


/* media queries */

@media only screen and (width: 540px) {
    .carousel {
        height: 42rem;
    }
    .caro-caption {
        margin: 0 1.5rem;
        font-size: 1.7rem;
    }
    .caro-p {
        margin: 0 1.5rem;
        font-size: 1.3rem;
    }
    .skill-div {
        margin: 1rem 1.5rem;
        font-size: 0.7rem;
    }
    .skill {
        font-size: 1rem;
        margin: 0.2rem 0;
        padding: 0rem 0.7rem;
    }
}

@media only screen and (max-width: 414px) {
    .carousel {
        height: 31rem;
    }
    .carousel-item {
        width: 100%;
    }
    .caro-caption {
        margin: 0 0.8rem;
        font-size: 1.3rem;
    }
    .caro-p {
        margin: 0 0.8rem;
        font-size: 1rem;
    }
    .skill-div {
        margin: 1rem 0.8rem;
        font-size: 0.7rem;
    }
    .skill {
        padding: 0rem 0.7rem;
        margin: 0.2rem 0;
    }
}

@media only screen and (width: 280px) {
    .carousel {
        height: 28rem;
    }
    .carousel-item {
        width: 100%;
    }
    .caro-caption {
        margin: 0 1.5rem;
        font-size: 1.15rem;
    }
    .caro-p {
        margin: 0 1.5rem;
        font-size: 0.98rem;
    }
    .skill-div {
        display: block;
        margin-left: auto;
        margin-right: auto;
        font-size: 0.7rem;
        margin: 0.2rem 1rem;
    }
    .skill {
        font-size: 0.6rem;
        line-height: 1;
        padding: 0.5rem 0.7rem;
    }
}