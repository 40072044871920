/* main container */

.project-section {
    background-color: #003459;
    padding: 3rem 0 5rem 0;
}


/* project heading */

.project-div {
    color: #00A8E8;
    display: inline-block;
    margin: 0 0 1.5rem 4rem;
}

.project-title {
    color: #f8d12f;
    font-size: 3rem;
    font-weight: bold;
    transform: rotate(-3deg);
}


/* marquee */

.marquee {
    height: 40rem;
}


/* card css properties */

.card-title {
    color: #2b3139;
    font-family: 'EB Garamond', serif;
    font-size: 1.8rem;
    text-shadow: 0px 1px 1px #00a8e8;
    font-weight: 600;
}

.text-color {
    color: #f84960;
    font-weight: bold;
}


/* media queries */

@media only screen and (max-width: 414px) {
    .project-div {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }
    .project-title {
        text-align: center;
        transform: rotate(0deg);
    }
    .marquee {
        height: 30rem;
    }
}

@media only screen and (width: 412px) {
    .project-title {
        font-size: 2.7rem;
    }
}

@media only screen and (width: 280px) {
    .project-title {
        font-size: 2.3rem;
    }
}